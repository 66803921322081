import React, { useRef, useEffect } from 'react';

const AnimatedTitle = ({ child }) => {
  const titleRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-title');
          } 
        });
      },
      { threshold: 0.5 } // Измените значение по вашему усмотрению
    );

    observer.observe(titleRef.current);

    return () => {
      observer.unobserve(titleRef.current);
    };
  }, []);

  return (
    <div className="animated-title-container" ref={titleRef}>
      {child}
    </div>
  );
};

export default AnimatedTitle;
