
import img1 from '../images/Group-414.png';
import img2 from "../images/Group-41.png";
import img3 from "../images/Group-435.png";
import img6 from '../images/Group-416.png';
import Heading from './Heading';
import works from '../works';
import React, { useState, useEffect } from "react";
import parse from 'html-react-parser';
import AnimatedImage from './AnimatedImage';
import AnimatedTitle from './AnimatedTitle';


const Content = ({ localization }) => {
  const [isDesktop, setIsDesktop] = useState(true);

  //choose the screen size 
const handleResize = () => {
  if (window.innerWidth > 1230) {
    setIsDesktop(true)
  } else {
    setIsDesktop(false)
  }
}

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  localization = localization.localization
  // Shuffle array
  const shuffled = works.filter(w => w.image !== '' && w.image !== null && w.image !== undefined).sort(() => 0.5 - Math.random());

  // Get sub-array of first n elements after shuffled
  let selected = shuffled.slice(0, 4);

  return(
    <section class="all-content">
    <div class="bg_image">
      {isDesktop ? <Heading /> :
      <div class="main_image">
      </div>}
    </div>
    <div class="div-block-2">
    <div class="w-row about-wrap">
        <div class="about-block">
          <div class="about-block__wrapper" id="about">
              <AnimatedTitle child={<h1 class="heading-2">{localization.title_about}</h1>}/>
              <div class="about-block__wrapper-text">
                <div>
                  <strong>{localization.text_about_span}</strong> 
                  <br/>
                  <br/>
                  {localization.text_about}
                  <span>{localization.span_about}</span>
                </div>
              </div>
          </div>
          <div class="column-11 w-col w-col-5">
            <AnimatedImage src={img6} alt="" className="image-about" />
          {/* <img src={img6} loading="lazy" alt="" class="image-about" /> */}
          </div>
        </div>
        <div class="div-block-5">
          <div class="text-block-3">26.06 - 14.07</div>
        </div>
      </div>
      <div class="w-row">
        <div class="column-11 w-col w-col-5">
          <AnimatedImage src={img1} alt="" className="image-5" />
          {/* <img src={img1} loading="lazy" alt="" class="image-5"/> */}
          </div>
        <div class="column-2 w-col w-col-7">
          <AnimatedTitle child={<h1 class="heading-2">{localization.title_mission}</h1>} />
          <div class="div-block-6">
            <div class="text-block-4">{localization.text_mission}</div>
          </div>
        </div>
      </div>
      <div class="w-row">
        <AnimatedTitle child={<h2 class="heading-3">{localization.title_theme}</h2>} />
      </div>
      <div class="w-row">
        <AnimatedTitle child={<div class="div-block-7">
          <h3 class="heading-5">{localization.courage}</h3>
        </div>} />
        <AnimatedTitle child={
          <div class="div-block-8">
          <h3 class="heading-6">{localization.to_speak}</h3>
        </div>
        } />
      </div>
      <div class="w-row">
        <div class="w-col w-col-6">
          <div class="div-block-9">
            <div class="text-block-5">{localization.text_theme}</div>
          </div>
        </div>
        <div class="w-col w-col-6">
          <AnimatedImage src={img2}  alt="" className="image-6" />
          {/* <img src={img2} loading="lazy" alt="" class="image-6"/> */}
        </div>
      </div>
      
      <div class="w-row">
        <AnimatedTitle child={<h2 class="heading-33">{localization.title_participants}</h2>}/>
        <div class="w-col w-col-4">
          <AnimatedImage src={img3} alt="" className="image-7" />
          {/* // <img src={img3} loading="lazy" alt="" class="image-7"/> */}
        </div>
        <div class="w-col w-col-8">
          <div class="div-block-10">
            <div class="text-block-6">{parse(localization.text_participants)}</div>
          </div>
        </div>
      </div>
      <AnimatedTitle child={<h2 class="heading-7">{localization.nav_gallery}</h2>} />
      <div class="collection-list-wrapper w-dyn-list">
        <div role="list" class="collection-list-2 w-dyn-items w-row">
          {selected.map((i) => <div role="listitem" class="collection-item w-dyn-item w-col w-col-3"><a href={`/works/${i.slug}`}><img height="283" loading="lazy" width="283" src={i.minImage} alt={i.alt} class="image-8"/></a></div>)}
        </div>
      </div>
      <div class="w-row">
        <a href="/works" class="button w-button"><span display="block" class="text-span-3">{localization.gallery_button}</span></a>
      </div>
    </div>
    </section>);
}


export default Content;