import React from 'react';
import works from '../works';
import Material from './Material';
import bird1 from '../images/bird1.png';
import bird2 from '../images/bird2.png';

const birds = [bird1, bird2, bird1];

const GalleryContent = ({ localization }) => {
  localization = localization.localization;
  const pathname = window.location.pathname;

  return (
    <div class="works-section">
      <h1 class="works-heading">{localization.nav_gallery}</h1>
      <div class="works-wrapper"> 
        <div className="column-1">{works.slice(0, 5).map(
          (i, index) => <div class="works-item">
            <a href={`${pathname.includes('en') ? '/en' : ''}/works/${i.slug}`} className="material-link">
                <Material 
                  totalMaterials={works.length}
                  index={index+1}
                  alt={i.alt}
                  caption={pathname.includes('en') ? i.captionEn || i.nameEn :
                  i.caption || i.name}
                  src={i.image ? i.minImage : birds[0]} />
            </a>
          </div>
        )}</div>
        <div className="column-2">{works.slice(5, 10).map(
          (i, index) => <div class="works-item">
            <a href={`${pathname.includes('en') ? '/en' : ''}/works/${i.slug}`} className="material-link">
                <Material 
                  totalMaterials={works.length}
                  index={index+1}
                  alt={i.alt}
                  caption={pathname.includes('en') ? i.captionEn || i.nameEn :
                  i.caption || i.name}
                  src={i.image ? i.minImage : birds[1]} />
            </a>
          </div>
        )}</div>
        <div className="column-3">{works.slice(10, 13).map(
          (i, index) => <div class="works-item">
            <a href={`${pathname.includes('en') ? '/en' : ''}/works/${i.slug}`} className="material-link">
                <Material 
                  totalMaterials={works.length}
                  index={index+1}
                  alt={i.alt}
                  caption={pathname.includes('en') ? i.captionEn || i.nameEn :
                  i.caption || i.name}
                  src={i.image ? i.minImage : birds[0]} />
            </a>
          </div>
        )}</div>
        <div className="column-4">{works.slice(13, 18).map(
          (i, index) => <div class="works-item">
            <a href={`${pathname.includes('en') ? '/en' : ''}/works/${i.slug}`} className="material-link">
                <Material 
                  totalMaterials={works.length}
                  index={index+1}
                  alt={i.alt}
                  caption={pathname.includes('en') ? i.captionEn || i.nameEn :
                  i.caption || i.name}
                  src={i.image ? i.minImage : birds[2]} />
            </a>
          </div>
        )}</div>
      </div>
    </div>
  );
};

export default GalleryContent;
