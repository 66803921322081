import React, {useEffect, useState} from "react";
import birds1 from '../images/birds-1.png';
import birds2 from '../images/birds-2.png';
import birds3 from '../images/birds-3.png';
import birds4 from '../images/birds-4.png';
import birds5 from '../images/birds-5.png';
import birds6 from '../images/birds-6.png';
import birds7 from '../images/birds-7.png';
import birds8 from '../images/birds-8.png';
// import birds9 from '../images/birds-9.png';
import birds10 from '../images/birds-10.png';
import birds11 from '../images/birds-11.png';
import birds12 from '../images/birds-12.png';
import birds13 from '../images/birds-13.png';
import birds14 from '../images/birds-14.png';
import birds15 from '../images/birds-15.png';
import birds16 from '../images/birds-16.png';
import birds17 from '../images/birds-17.png';
import birds18 from '../images/birds-18.png';
import birds19 from '../images/birds-19.png';
import birds20 from '../images/birds-20.png';
import birds21 from '../images/birds-21.png';
import birds22 from '../images/birds-22.png';
import birds23 from '../images/birds-23.png';
import birds24 from '../images/birds-24.png';
import birds25 from '../images/birds-25.png';
import birds26 from '../images/birds-26.png';
import birds28 from '../images/birds-26.png';
import yb from '../images/birds-27.png';
import img2023 from '../images/2023.png';
import works from '../works';

const birds = [birds16,birds22,birds3,birds6,birds5,birds26,birds14,img2023,birds13,birds20,birds18,birds11,birds23,birds8,birds2,birds25,birds18,birds12,birds4,birds12,birds28,birds4,birds24,birds14,birds10,yb,birds17,birds15,birds21];
const birdsWithWorks = birds.map((b) => {
  const randomIndex = Math.floor(Math.random() * works.length);
  const randomWork = works[randomIndex]; 
  return { bird: b, ...randomWork }
});

const Heading = () => {
  const [randomElement, setRandomElement] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setRandomElement(birdsWithWorks[Math.floor(Math.random() * birdsWithWorks.length)]);
    }, 2000);

    return () => clearInterval(interval);
  }, [birdsWithWorks]);

  const pathname = window.location.pathname;

  console.log('b', birdsWithWorks)
  return (
    <div className="image-grid">
      <div className="image-grid-wrapper">
        {birdsWithWorks.map((i, index) => 
          i.slug ? <a href={`${pathname.includes('en') ? `/en/works/${i.slug}` : `/works/${i.slug}`}`} key={index} className="works-in-heading">
            {index !== 7 && index !== 15 && i.image ? <img src={i.image} className="under-image-grid-item"/> : null}
            <img src={i.bird} className={`image-grid-item image-grid-item-${index+1} ${i === randomElement ? 'mirror-animation' : ''}`} />
          </a> : <img src={i.bird} className={`image-grid-item image-grid-item-${index+1}`} />
        )}
      </div>
    </div>
  );
};

export default Heading;