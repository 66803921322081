import React, { useState } from "react";
import { useParams } from 'react-router-dom';

const Header = ({ localization }) => {
  const pathname = window.location.pathname;
  console.log('pathname', pathname)
  localization = localization.localization;
  const [isShown, setIsShown] = useState(false);
  const [isMobOpen, setIsOpen] = useState(false);

  const years = [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022];

  return (<div class="navbar-logo-center wf-section">
    <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar-logo-center-container shadow-three w-nav">
      <div class="container">
        <div class="navbar-wrapper-three">
          <nav role="navigation" class={`nav-menu-wrapper-three w-nav-menu`}  {...(isMobOpen ? {'data-nav-menu-open': ''} : {})} >
            <div class="nav-menu-three">
              <ul role="list" class="nav-menu-block w-list-unstyled">
                <li>
                  <a href={pathname.includes('en') ? "/en/#about" : "/#about"} class="nav-link">{pathname === '/' ? localization.nav_about_title : localization.nav_about_main}</a>
                </li>
                <li>
                  <a href={pathname.includes('en') ? "/en/works" : "/works"} class="nav-link">{localization.nav_gallery}</a>
                </li>
                <li>
                  <div data-hover="false" data-delay="0" class="nav-dropdown w-dropdown">
                    <div class="nav-dropdown-toggle w-dropdown-toggle" onClick={() => setIsShown(!isShown)}>
                      <div class="text-block">{localization.nav_archive}</div>
                    </div>
                  {isShown && <nav class="nav-dropdown-list shadow-three mobile-shadow-hide w-dropdown-list w--open">
                      {years.map(y => <a href={`https://${y}.queerfest.org${pathname.includes('en') ? '/en' : ''}`} class="nav-dropdown-link w-dropdown-link">{y}</a>)}
                    </nav>}
                  </div>
                </li>
              </ul>
              {/* todo: uncomment for eng */}
              <ul role="list" class="nav-menu-block w-list-unstyled">
                <li>
                  <a href={`/${localization.nav_lang === 'RU' ? 'ru' : 'en'}${pathname.replace('en/', '').replace('ru/', '')}`} class="nav-link-accent">{localization.nav_lang}</a>
                </li>
              </ul>
            </div>
          </nav>
          <div class="menu-button w-nav-button" onClick={() => {
            setIsOpen(!isMobOpen)
          }}>
            <div class="w-icon-nav-menu"></div>
          </div>
        </div>
      </div>
    </div>
    </div>)
}

export default Header;