import React, { useEffect, useRef, useState } from 'react';

const Material = ({ index, src, alt, caption, totalMaterials }) => {
  
  const materialRef = useRef(null);
  const captionRef = useRef(null);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const materialElement = materialRef.current;
    const captionElement = captionRef.current;

    const handleScroll = () => {
      const position = materialElement.getBoundingClientRect().top;
      const isVisible = position - window.innerHeight <= 0;
      if (isVisible && !isAnimating) {
        setIsAnimating(true);
        setTimeout(() => {
          materialElement.classList.add('appear');
          setTimeout(() => {
            setIsAnimating(false);
          }, 5000);
          setTimeout(() => {
            captionElement.classList.add('slide');
          }, 100);
        }, (index / totalMaterials) * 5000);
      }
    };

    const initialPosition = materialElement.getBoundingClientRect().top;
    const isInitiallyVisible = initialPosition - window.innerHeight <= 0;
    if (isInitiallyVisible && !isAnimating) {
      setIsAnimating(true);
      setTimeout(() => {
        materialElement.classList.add('appear');
        setTimeout(() => {
          setIsAnimating(false);
        }, 5000);
        setTimeout(() => {
          captionElement.classList.add('slide');
        }, 100);
      }, (index / totalMaterials) * 5000);
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [index, totalMaterials, isAnimating]);

  return (
    <div>
      <img 
        style={{ animationDelay: `${index * 5}s` }}
        loading="lazy" 
        className="material" 
        src={src} 
        alt={alt} 
        ref={materialRef}
        onLoad={() => setIsAnimating(false)} />
      <div className="caption" ref={captionRef} >{caption}</div>
    </div>
  );
};

export default Material;
