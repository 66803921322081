import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Main from './pages/Main';
import MainEn from './pages/Main';
import Works from './pages/Works';
import Work from './pages/Work';
import config from './config';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main localization={config.ru} />,
  },
  {
    path: "/en",
    element: <MainEn localization={config.en} />,
  },
  {
    path: "/ru",
    element: <Main localization={config.ru} />,
  },
  {
    path: "/ru/works",
    element: <Works localization={config.ru} />,
  },
  {
    path: "/en/works",
    element: <Works localization={config.en} />,
  },
  {
    path: "/works",
    element: <Works localization={config.ru} />,
  },
  {
    path: "/works/:slug",
    element: <Work localization={config.ru} />,
  },
  {
    path: "/ru/works/:slug",
    element: <Work localization={config.ru} />,
  },
  {
    path: "/en/works/:slug",
    element: <Work localization={config.en} />,
  },
]);

function App() {
  return (
    <RouterProvider router={router} basename={process.env.PUBLIC_URL} />
  );
}

export default App;
