import daren from './images/daren.jpg';
import darenMin from './images/daren-min.jpg';
import onchyart from './images/onchyart.jpg';
import onchyartMin from './images/onchyart-min.jpg';
import uni1 from './images/uni1.png';
import uni1Min from './images/uni1-min.jpg';
import uni2 from './images/uni2.png';
import uni3 from './images/uni3.png';
import uni4 from './images/uni4.png';
import uni5 from './images/uni5.png';
import uni6 from './images/uni6.png';
import uni7 from './images/uni7.png';
import uni8 from './images/uni8.png';
import disco1 from './images/disco1.jpg';
import disco1Min from './images/disco1-min.jpg';
import disco2 from './images/disco2.jpg';
import fukaitoi1 from './images/fukaitoi1.jpg';
import fukaitoi1Min from './images/fukaitoi1-min.jpg';
import fukaitoi2 from './images/fukaitoi2.jpg';
import fukaitoi3 from './images/fukaitoi3.jpg';
import fukaitoi4 from './images/fukaitoi4.jpg';
import fukaitoi5 from './images/fukaitoi5.jpg';
import fukaitoi6 from './images/fukaitoi6.jpg';
import time from './images/time.png';
import timeMin from './images/time-min.jpg';
import crush from './images/crush.png';
import crushMin from './images/crush-min.jpg';
import path from './images/path.jpg';
import pathMin from './images/path-min.jpg';
import zamok from './images/zamok.jpg';
import zamokMin from './images/zamok-min.jpg';
import pride from './images/pride.png';
import prideMin from './images/pride-min.jpg';
import boy from './images/boy.jpg';
import boyMin from './images/boy-min.jpg';
import oogrum from './images/oogrum.jpg';
import oogrumMin from './images/oogrum-min.jpg';
import young from './images/young.png';
import youngMin from './images/young-min.jpg';
import moary from './images/moary.png';
import moaryMin from './images/moary-min.jpg';
import siren3 from './images/siren-3.png';
import siren3Min from './images/siren-3-min.jpg';
import siren4 from './images/siren-4.png';
import siren5 from './images/siren-5.png';
import siren6 from './images/siren-6.png';
import siren7 from './images/siren-7.png';


let works = [
  {
    author: 'Дарья Гордеева',
    authorEn: 'Darya Gordeeva',
    name: 'Люблю тебя, но…',
    nameEn: 'I love you, but…',
    caption: 'Люблю тебя, но… (видео)',
    captionEn: 'I love you, but… (video)',
    image: '',
    minImage: '',
    videoId: 'GWJ0lAMXRAQ',
    description: `Режиссёр, журналистка и писательница. 
    <br/>
    <br/>
    «Мою повесть «Кто такая Джейн» сняли с публикаций из-за дискриминационного закона о пропаганде. Я веду блог и пишу статьи на темы феминизма, лгбт и пр.».

    Это короткометражный 
    социальный ролик о том, как чувствует себя квир-человек 
    в гомофобной среде. Навеяно 
    множеством личных историй 
    друзей, близких и личным опытом. 
    `,
    descriptionEn: `Producer, journalist, and writer. “My short novel «Who is Jane?» was withdrawn from publication because of the discriminatory propaganda laws. I have a blog and write articles on topics of feminism, LGBTQ+, etc.”.
    <br/>
    <br/>
    This is a short film about how a queer person feels in a homophobic society. It’s inspired by many stories from my friends, close ones, as well as personal experience.
    `,
    alt: '',
    slug: 'iloveubut'
  },
  {
    author: 'onchyart, фрилансер иллюстратор',
    authorEn: 'onchyart, freelance illustrator',
    name: 'То что нужно',
    nameEn: 'Just what I need',
    image: onchyart,
    minImage: onchyartMin,
    video: '',
    description: `Графика на тему дружбы и сотрудничества: «Эта работа о людях, которые
    ищут что-то в необычных
    местах и находят в еще 
    более фантастических».
    `,
    descriptionEn: `
      Graphic art on the topic of friendship and collaboration. «This work is about people who search for something in unusual places and find that something in an even more fantastical place».
    `,
    alt: 'onchyart illustation',
    slug: 'need'
  },
  {
    author: 'Дарен',
    authorEn: 'Daren',
    name: 'Вместе',
    nameEn: 'Together',
    image: daren,
    minImage: darenMin,
    video: '',
    description: `Трансперсона, художник. аниматор. «33 года рисую с переменным успехом. Докатившись до ПНД, арт-терапию встретил, как живую воду. Коллаж искусством не считал, но все изменилось».
    <br/>
    <br/>
    Коллаж на тему жизни: «Да. Я взял задачу - что хорошего в этом дне. Выросло, как и в любом другом. Связь».    
    `,
    descriptionEn: `
    Transgender person, artist, animator. «I have been drawing for 33 years, with alternating success. Having ended up in a psychoneurological dispensary, I welcomed art therapy like the water of life. I didn’t consider collage art, but everything changed».
    <br/>
    <br/>
    Collage about life: «Yes. I took up the task: is there anything good today?. It has uprooted, like any other. The connection».

    `,
    alt: 'work of daren',
    slug: 'together'
  },
  {
    author: `Юни Демьянова  («Когда я вырасту, я стану 
      анархистом»)       
    `,
    authorEn: `Yuni Demyanova («When I grow up, I’ll be an anarchist.»)`,
    name: 'Просто человек',
    nameEn: 'Just a person',
    image: uni1,
    minImage: uni1Min,
    additional: [uni2, uni3, uni4, uni5, uni6, uni7, uni8],
    video: '',
    description: `Студентка
    <br/>
    <br/>
    «Серия работ, вышедшая сразу 
    после принятия последнего гомофобного закона. В глазах 
    смотрящего только его любовь 
    и личность, и никакой пропаганды».
    <br/>
    <br/>
    «Меня, как художницу-активистку, на фестиваль привела возможность внести свой маленький вклад в проект».    
    `,
    descriptionEn: `
    Student
    <br/>
    <br/>
    «Series of works that was released after the latest homophobic rhetoric was signed into law. There is only love, personality, and no propaganda in the beholder’s eyes. Personally, as an artist and an activist, I came to the festival to bring my little contribution to the project».
    `,
    alt: 'work of uni',
    slug: 'just'
  },
  {
    author: `Лера Олейникова`,
    authorEn: 'Lera Oleinikova',
    name: `Серия из двух работ. 
    «Дискотека» и «Дискотека 2»`,
    nameEn: 'Two-work series. “Disco” and “Disco 2”',
    image: disco1,
    minImage: disco1Min,
    additional: [disco2],
    video: '',
    description: `Художница из Новосибирска, живет и работает в Москве. «В своих картинах я хочу показать характер изображаемых персон, их противоречия, внутренние конфликты и ощущения. Больше всего я люблю писать портреты и обнаженную натуру, так как самое интересное для меня –  человек. Я добиваюсь выразительности с помощью колорита и фактуры. Мой любимый материал – масло, но иногда я также использую шпаклевку, аэрозольную краску, газеты. Я считаю ключевым мой взгляд как квир-художницы на натуру, в этом я вижу актуальность и уважительное, даже заботливое отношение к героиням и героям моих картин».
    <br/>
    <br/>
    Тематика работ: сплочение, объединение, стирание границ и преодоление препятствий, конфликтов и различий. «В этой серии работ я пыталась 
    поймать момент полного 
    растворения человека в
     музыке и танце. В этот 
    момент не существует 
    границ между людьми, 
    мы все оказываемся на 
    одной волне, в гармонии».
       
    `,
    descriptionEn: `
    Artist from Novosibirsk, lives and works in Moscow. «In my paintings, I want to show the depicted persons’ characters, their contradictions, their inner struggles, and their feelings. I like painting portraits and nudes more than anything else, as my ultimate interest is the human being. I strive for expressiveness using color and facture. My favorite thing to work with is oil; however, I also sometimes use putty, aerosol paint, and newspapers. I believe that, as a queer artist, my outlook on nature is key; in it, I see relevance and a respectful and even caring attitude towards heroes and heroines in my paintings».
    <br/>
    <br/>
    Topics of my works: unification, collaboration, the erasure of borders, and overcoming obstacles, conflicts, and differences. «In this two-work series, I tried to capture the moment of a person’s full immersion in music and dance. At this moment, there are no boundaries between people; we all end up on the same wavelength, in harmony».
    `,
    alt: 'work of lera oleynikova',
    slug: 'disco'
  },
  {
    author: `fukai toi`,
    authorEn: 'fukai toi',
    name: `Какая ты сегодня?`,
    nameEn: 'Designed, book author',
    image: fukaitoi1,
    minImage: fukaitoi1Min,
    additional: [fukaitoi2, fukaitoi3, fukaitoi4, fukaitoi5, fukaitoi6],
    video: '',
    description: `Дизайнер, автор книг
    <br/>
    <br/>
    Серия коллажей о дискриминации по внешнему виду: «Это высмеивание стереотипного мышления людей, привыкших судить человека по внешнему виду».     
    `,
    descriptionEn: `
    Collage series about discrimination on the grounds of appearance. «This mocks the society’s prejudiced ways of thinking, people who got used to judging people on their appearance».
    `,
    alt: 'work of fukai toi',
    slug: 'today'
  },
  {
    author: `Николай Миклухо-Маклай, автор песен.`,
    authorEn: 'Nikolay Miklukho-Maklay, songwriter.',
    name: `Сон`,
    nameEn: 'Sleep',
    caption: 'Сон (песня)',
    captionEn: 'Sleep (song)',
    image: '',
    minImage: '',
    video: '',
    audio: 'https://soundcloud.com/queerfest/nikolay-miklukho-maklay-son',
    description: `Песня на тему отношений между двумя парнями. Осмысление удачных и неудачных 
    опытов отношений.         
    `,
    descriptionEn: `
      Song about relationships between two guys. Self-reflecting on successful and unsuccessful relationships.
    `,
    alt: 'work of Nikolay',
    slug: 'sleep'
  },
  {
    author: `Маша (wercat.png/werrrcat) `,
    authorEn: 'Masha (wercat.png/werrrcat)',
    name: `Время не щадит`,
    nameEn: 'Time waits for no one',
    image: time,
    minImage: timeMin,
    video: '',
    description: `Художница и аниматорка, музыкантка.
    <br/>
    <br/>
    «Эта работа вдохновлена 
одной из последних песен Земфиры. Я очень часто вдохновляюсь музыкой при создании своих работ, конкретными песнями. Эта работа – тревожное ощущение неизбежного конца, смерти, уже видимой. Тем не менее, героини - вместе, вот-вот 
возьмутся за руки и разделят конец на двоих».            
    `,
    descriptionEn: `
    Artist, animator, and musician.
    <br/>
    <br/>
    «This work is inspired by one of Zemfira's latest creations. I very frequently get inspired by music when creating my works, by specific songs. This work is an anxiety-inducing sensation of impending doom and death closing in on you. Nevertheless, the heroines are together; they are to soon hold hands and share the fate».

    `,
    alt: 'work of wercat.png',
    slug: 'time'
  },
  {
    author: `Илья`,
    authorEn: 'Ilya',
    name: `Нас не задавишь`,
    nameEn: 'You can’t oppress us',
    image: crush,
    minImage: crushMin,
    video: '',
    description: `Транс-парень, комиксист, рисует стрипы. Волонтер и просто любитель творчества.
    <br/>
    <br/>
    Графика на тему опрессии квир-сообщества: «Переживания в связи с
    последними событиями в России».                  
    `,
    descriptionEn: `
    Transmasc, comic strip artist. Volunteer and an art enthusiast.

    Graphic art on the topic of queer oppression. «Emotional distress due to the recent events in Russia».
    `,
    alt: 'work of ilya',
    slug: 'wontcrush'
  },
  {
    author: `Крис`,
    authorEn: 'Chris',
    name: `На пути к приговору`,
    nameEn: 'On the way to the verdict',
    image: path,
    minImage: pathMin,
    video: '',
    description: `Студент
    <br/>
    <br/>
    Работа на тему закона о запрете перехода для трансперсон: «В лице обвиняемого мне хотелось показать каждого т*человека в России».                 
    `,
    descriptionEn: `
    Student
    <br/>
    <br/>
    Work on the topic of the recent law that bans gender transitioning. «The arrested person in my work represents every trans person in Russia».

    `,
    alt: 'work of kris',
    slug: 'path'
  },
  {
    author: `Замок фоулис в огне`,
    authorEn: 'Faulis Castle on Fire',
    name: `Свадьба красного короля и белой королевы`,
    nameEn: 'Red king and white queen’s wedding',
    image: zamok,
    minImage: zamokMin,
    video: '',
    description: `Квир с Дальнего Востока, учится на культуролога, любит рисовать и читать. Решила участвовать в фестивале, потому что очень хотела почувствовать связь с сообществом и быть увиденной.
    <br/>
    <br/>
    Графика на тему: гендерная некомфортность, идентичность, алхимия. «В алхимии одним из символов философского камня является ребис - гермафродит, соединяющий в себе мужские и женские качества. Для меня это как-то само собой наложилось на размышления о трансгендерности и гендерной идентичности в целом. хочу оставить «Свободу для интерпретаций, так что на этом и остановлюсь».                   
    `,
    descriptionEn: `
    Queer person from the Far East, a culturologist, likes to draw and read. Decided to participate in the festival because she wanted to bond with the community and be seen.
    <br/>
    <br/>
    Graphic art on the topics of gender nonconformity, identity, and alchemy. «In alchemy, one of the symbols of the philosopher’s stone is a Rebis, a hermaphrodite, a being of both male and female qualities. For me, it has by itself overlapped with thoughts about being trans and gender identity in general. I want to leave  «some freedom for interpretation», so I’ll stop at this».
    `,
    alt: 'work of zamok foulis v ogne',
    slug: 'wedding'
  },
  {
    author: `Яша`,
    authorEn: 'Yasha',
    name: `NOT GONNA HIDE MY PRIDE`,
    nameEn: 'NOT GONNA HIDE MY PRIDE',
    image: pride,
    minImage: prideMin,
    video: '',
    description: `Художница-иллюстраторка, тьюторка
    <br/>
    <br/>
    «Это ужасно, но у людей в России нет возможности по-настоящему праздновать Месяц Гордости. У многих из нас даже нет варианта быть открытыми оставшиеся одиннадцать месяцев в году. Мы используем всяческие ленты, значки и украшения, чтобы дать друзьям понять, что они не одни, дать им знать, что с нами они в безопасности. Единорог - символ чистоты, силы и твёрдости, виноград означает плодородие и изобилие. Я попробовала изобразить дух нашего сообщества, герб, которым действительно можно годиться. Хочется верить, что в будущем мы сможем носить наши цвета открыто, ничего не боясь. Будем сильными, как единорог и цепкими, как лоза винограда чтобы это будущее скорее увидеть! Спасибо, что вы есть. Я рада, что вы есть.»           `,
    descriptionEn: `
    Illustration artist, tutor
    <br/>
    <br/>
    «It's terrible, but people in Russia don't have the opportunity to truly celebrate Pride Month. Many of us don't even have the option to be open for the remaining eleven months of the year. We use various ribbons, badges, and decorations to let our friends know that they are not alone, to let them know that they are safe with us. The unicorn symbolizes purity, strength, and resilience, while the grapes represent fertility and abundance. I tried to depict the spirit of our community, a coat of arms that truly suits us. I’d like to believe that in the future, we will be able to proudly wear our colors without fear. Let us be as strong as a unicorn and as tenacious as grapevines to see that future come sooner! Thank you for being there. I'm glad you exist».
    `,
    alt: 'work of yasha',
    slug: 'pride'
  },
  {
    author: `Оксана Жукова`,
    authorEn: 'Oksana Zhukova',
    name: `Рисую. Запястье с радужной ленточкой`,
    nameEn: 'Me drawing. A wrist with a rainbow ribbon',
    caption: 'Рисую. Запястье с радужной ленточкой (Стихи)',
    image: '',
    minImage: '',
    video: '',
    poetry: `<b>Рисую</b> <br/>
    <br/>
    Рисую, рисую, рисую… Ловлю <br/>
    Украдкой его в застывающей позе, <br/>
    Впитать бы, запомнить таким, как люблю. <br/>
    Ведь дальше нельзя – превышение дозы.<br/>
    <br/>
    Ведь дальше – запрет. Прикушу губы в кровь.  <br/>
    Нельзя перейти через эту границу. <br/>
    Веками судима такая любовь, <br/>
    Да как так случилось мне в парня влюбиться?!<br/>
    <br/>
    Сожгу свой альбом. Моя боль - компромат. <br/>
    Никто никогда ничего не узнает… <br/>
    Не должен узнать… И я этому рад. <br/>
    Но только как пепел уверенность тает… <br/>
    <br/>
    Он точно поймет, если наши глаза <br/>
    Вдруг встретятся хоть на секунду случайно. <br/>
    Он точно поймет. Что мне делать тогда? <br/>
    Как быть, если все же раскроется тайна. <br/>
    <br/>
    Как пульс успокоить? Как сердце унять? <br/>
    При виде него мне бы стать невидимкой <br/>
    И, задыхаясь, так крепко обнять… <br/>
    …Я снова в альбоме рисую картинку: <br/>
    <br/>
    И снова его… шею, губы и взгляд… <br/>
    На этом рисунке мы можем быть вместе, <br/>
    Но я не осмелюсь себя рисовать, <br/>
    И чтобы не занял никто – ставлю крестик. <br/>
    <br/>
    Он с девочкой дружит, а я так хочу,  <br/>
    Чтоб это мы вместе ходили с ним парой <br/>
    Он хлопает дружески мне по плечу -  <br/>
    И вся моя кровь, как река Ниагара <br/>
    <br/>
    Бурлящим потоком срывается вниз,  <br/>
    И воздуха мало, и мало мгновенья, <br/>
    Еще на немного… Чуть-чуть задержись… <br/>
    «Чего ты так вздрогнул? Я не приведенье», - <br/>
    <br/>
    Смеется. А я… все бросая, бегу. <br/>
    Бегу от него, от себя, от ответа <br/>
    На самый обычный вопрос «Почему <br/>
    Со мною случается именно это?» <br/>
    <br/>
    А есть ли на свете такие места, <br/>
    Где можно сидеть, рисовать, не бояться, <br/>
    Что кто-то увидит, что смотрит с листа <br/>
    Тот самый, кем я не могу надышаться? <br/>
    <br/>
    Где можно за ручку идти двум парням, <br/>
    Не слыша угрозы в презрительном свисте, <br/>
    Где можно свободно такому как я <br/>
    Не прятать рисунки, и чувства, и мысли. <br/>
    
    <br/>
    <b>Запястье с радужной ленточкой</b>
    <br/>
    <br/>
    Запястье с радужной <span class="yellow">ленточкой</span> <br/>
    Прячу в кармане <span class="green">сзади,</span> <br/>
    И в строгих рамках <span class="blue">законности</span> <br/>
    Не смею руку <span class="purple">достать.</span> <br/>
    Чужая смелая <span class="yellow">девочка,</span> <br/>
    Пройди за меня на <span class="green">параде,</span> <br/>
    Блистай на празднике <span class="blue">гордости,</span> <br/>
    Как я мечтаю <span class="purple">блистать.</span> <br/>
    <br/>
    Свою подружку <span class="yellow">любимую</span> <br/>
    В губы целуй <span class="green">прилюдно,</span> <br/>
    Танцуй, обнимайся, <span class="blue">радуйся,</span> <br/>
    Дари всем счастье и <span class="purple">смех </span><br/>
    Возьми мое фото с <span class="yellow">именем,</span> <br/>
    Ну если, конечно, не <span class="green">трудно,</span> <br/>
    Как будто я с вами <span class="blue">рядышком,</span> <br/>
      Неси его выше <span class="purple">всех!</span> <br/>
      <br/>
    А я усталая <span class="yellow">вечером </span><br/>
    Чаю с цветным <span class="green">зефиром </span><br/>
    Напьюсь, под Монэ и <span class="blue">Боуи</span> <br/>
    Стихи начну <span class="purple">сочинять. </span><br/>
      Чужая смелая <span class="yellow">девочка </span><br/>
      Помашет рукой мне в <span class="green">эфире,</span> <br/>
      И фото мелькнет <span class="blue">знакомое… </span><br/>
      А я боялась <span class="purple">мечтать. </span><br/>
      <br/>
    Когда-нибудь наши <span class="yellow">улицы </span> <br/>
    Флаги из сотен <span class="green">радуг </span><br/>
    Взволнуют, наполнят <span class="blue">красками,</span> <br/>
     И никого не <span class="purple">смутит,</span> <br/>
      Что парни в губы <span class="yellow">целуются</span> <br/>
      Сплоченные признанным <span class="green">прайдом.</span> <br/>
      И все мы такие <span class="blue">разные,</span> <br/>
      И нам каждый путь <span class="purple">открыт.</span> <br/>
      <br/>
    Запястье с радужной <span class="yellow">ленточкой</span> <br/>
    Вытащу из <span class="green">кармана.</span> <br/>
    Спасибо, чужая <span class="yellow">девочка,</span> <br/>
    Я знаю: уже не <span class="green">рано… </span><br/>
    
    `,
    description: `Поэт. Учится на психолога. Юрист. Пишет с 7 лет. Проявляет в творчестве свои мысли и чувства, своё Я.
    <br/>
    <br/>
    Стихи на тему преодоления препятствий и объединения. Проекция подростковых переживаний, что ты не такой как все. Пора что-то менять.     `,
    descriptionEn: `
    Poet. Psychology student. Lawyer. Has been writing since the age of 7. Uses art to express their thoughts and feelings, their Self.
    <br/>
    <br/>
    Poems about overcoming obstacles and coming together. A projection of the teenage experience of not fitting in. It's time for a change.
    `,
    alt: 'work of oksana zhukova',
    slug: 'wrist'
  },
  {
    author: `Сергей Аникин`,
    authorEn: 'Sergey Anikin',
    name: `Парнишка, прижимающий к щеке нежные лепестки ароматных лилий`,
    nameEn: 'A lad clutching delicate petals of fragrant lilies to his cheek',
    image: boy,
    minImage: boyMin,
    video: '',
    description: `Переводчик

    <br/>
    <br/>
    
    Всегда считал несправедливым, 
    что Альфонс Муха изображал 
    преимущественно женщин, а 
    мужчин крайне редко, и не в таких антуражах. Считаю, что сильный мускулистый мужчина в окружении цветов и узоров становится только привлекательнее. За счёт яркого контраста мужественность становится ярче, мощнее, пронзительнее.     `,
    descriptionEn: `
    I always thought it was unfair that Alphonse Mucha mostly portrayed women. In the rare cases when he painted men he did not use the same kind of backgrounds. I believe that a strong, muscular man surrounded by flowers and patterns only becomes more attractive. The vivid contrast makes the masculinity brighter, more powerful, more piercing.
    `,
    alt: 'work of sergey anikin',
    slug: 'boy'
  },
  {
    author: `oogrum`,
    authorEn: 'oogrum',
    name: `Моральный выбор`,
    nameEn: 'The moral choice',
    image: oogrum,
    minImage: oogrumMin,
    video: '',
    description: `Иллюстратор
    <br/>
    <br/>
    
    «У меня полное ощущение того, 
что власть может интерпретировать 
законы, как ей удобно».
    `,
    descriptionEn: `
    Illustrator
    <br/>
    <br/>
    «I totally have the feeling that the authorities can interpret the laws as they see fit.»
    `,
    alt: 'work of oogrum',
    slug: 'choice'
  },
  {
    author: `EDarbye`,
    authorEn: 'EDarbye',
    name: `Молодой человек`,
    nameEn: 'Young man',
    image: young,
    minImage: youngMin,
    video: '',
    description: `Диджитал художник, студент

    <br/>
    <br/>
    
    «Первоначально в работе чувство изоляции и одиночества выходили на первый план, но намного важнее показать: ты не взаперти, ты не один, тебя окружают такие же как ты, вы все в одной лодке и вы обязательно выйдете из шторма, ведь вы сильнее, чем думаете. Это работа про меня и про таких же людей. Ко мне часто могут обратиться «молодой человек» из-за моей внешности (короткая стрижка, широкая одежда). Но с другой стороны, сколько ещё таких молодых людей, против войны, насилия, стереотипов, стремящихся сделать мир вокруг себя лучше, достичь лучшего будущего для себя и людей, разделяющих их ценности. Словами сложно выразить все, что хочется сказать, но, надеюсь, люди поймут мои мысли, посмотрев на картинку». `,
    descriptionEn: `
    Digital artist, student
    <br/>
    <br/>
    «Initially the sense of isolation and loneliness came to the forefront in the work, but it's much more important to show: you're not locked up, you're not alone, you're surrounded by people like you, you're all in the same boat and you will definitely come out of the storm, because you're stronger than you think. This work is about me and people like me. People often address me as 'young man' because of my appearance (short haircut, baggy clothing). But on the other hand, there are so many more young people who are against the war, violence, stereotypes, and strive to make the world a better place, to achieve a brighter future for themselves and like-minded individuals. It's difficult to express everything I want to say in words, but I hope people will understand my thoughts by looking at the picture».
    `,
    alt: 'work of EDarbye',
    slug: 'young'
  },
  {
    author: `SIREN`,
    authorEn: 'SIREN',
    name: `9 мая`,
    nameEn: '9th of May',
    image: siren3,
    minImage: siren3Min,
    additional: [siren4,siren5,siren6,siren7],
    video: '',
    description: `Квир-художница в своих работах исследует границы, на которых сосуществуют общество и индивидуальность, социум и личность. Фокусом внимания выбирает социально-политические темы в локальном и мировом контексте: уязвимость квир сообщества, война, неравенство, тяжелые эмоциональные состояния, кризисные периоды жизни. В своих работах художница использует различные медиа, от саунд-арта до инсталляции. Родилась в 1991 г. в г. Ульяновск. Живет и работает в Москве.
    <br />
    <br />
    
    Серия коллажей «9 мая»
    рассказывает личную историю знакомства и зарождающихся 
    отношений на фоне войны. Москва, преображенная плакатами к 9 мая и агитационной военной символикой, стала фоном для романтических прогулок, а 9 мая - день, когда 
    девушки решили начать встречаться. 
    Москва, теперь совсем чужая, война, ощущение угрозы и чужеродности 
в этом городе смешиваются со страстью и влюбленностью, 
образую контрапункт. В работе и использованы отрывки из личной переписки и фотографии Москвы  весны 2023 года.

     `,
    descriptionEn: `
    The queer artist’s work explores the boundaries on which society and the individual coexist. It focuses on socio-political themes in local and global contexts: the vulnerability of the queer community, the war, inequality, severe emotional states, and life crises. The artist uses different media, from sound art to installation. The artist was born in 1991 in Ulyanovsk, currently lives and works in Moscow.
    <br/>
    <br/>
    A series of collages, "9th of May", tells a personal story of dating and a budding relationship against the backdrop of war. Moscow, transformed by 9th of May posters and propaganda military symbols, became the backdrop for romantic strolls, and the 9th of May is the day the women decided to start dating. Moscow, now completely foreign, the war, the sense of threat and alienation in this city blend with passion and love, forming a counterpoint. The work uses excerpts from personal correspondence and photographs of Moscow in the spring of 2023.
    `,
    alt: 'work of SIREN',
    slug: '9thmay'
  },
  {
    author: `Moary, художник`,
    authorEn: 'Moary, graphic artist',
    name: `Красивые шрамы`,
    nameEn: 'Beautiful scars',
    image: moary,
    minImage: moaryMin,
    video: '',
    description: `Графика на тему жизни после транс*перехода: «Для многих сам переход является 
    важным и переломным событием в жизни, но даже после него путь самопринятия продолжается, в обществе все еще много других агрессивных ярлыков, в том числе насчет шрамов».     `,
    descriptionEn: `
    «For many, the transition itself is an important and life-changing event, but the journey of self-acceptance continues even afterwards. There are still many other aggressive labels in society, including those about scars».
    `,
    alt: 'work of Moary',
    slug: 'beauty'
  },
]

export default works;