import Header from '../components/header';
import Footer from '../components/footer';
import GalleryContent from '../components/GalleryContent';
import React from 'react';

const Works = (localization) => (
  <React.Fragment>
    <Header localization={localization} />
    <GalleryContent localization={localization} />
    <Footer localization={localization} />
  </React.Fragment>
);

export default Works;