import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import works from '../works';
import parse from 'html-react-parser';
import ReactPlayer from "react-player";
import bird1 from '../images/bird1.png';
import bird2 from '../images/bird2.png';

const birds = [bird1, bird2];
const rotatedBird = birds.sort(() => 0.5 - Math.random())[0];

const WorkContent = ({ localization }) => {
  const pathname = window.location.pathname;

  const [isDesktop, setIsDesktop] = useState(true);

    //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth > 1230) {
      setIsDesktop(true)
    } else {
      setIsDesktop(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  localization = localization.localization;
  const getPost = slug => {
    return works.find(w => w.slug === slug);
  };
  let post = getPost(useParams().slug);

  return (
    <div class="work-section">
      <div class="work-heading">
        <div>
          <h1 class="work-heading">{localization.nav_gallery}</h1>
          <h2>«{pathname.includes('en') ? post.nameEn : post.name}»</h2>
        </div>
        <div>
          {isDesktop && <img src={rotatedBird} />}
        </div>
      </div>
      <div class="work-wrapper"> 
       
        <div class="work-columns">
          <div class="left-column">
            {post.image !== '' && <img src={post.image} alt={post.alt} />}
            {post.additional && post.additional.map(a => <img src={a} />)}
            {post.videoId && 
              <iframe
              width="640"
              height="480"
              style={{ maxWidth: "100%" }}
              src={`https://www.youtube.com/embed/${post.videoId}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />}
            {post.pdf && <a href={post.pdf} class="pdf-link">Скачать pdf</a>}
            {post.poetry && <p class="poetry">{parse(post.poetry)}</p>}
            {post.audio && <ReactPlayer width="300px" height="150px" url={post.audio}/>}
          </div>
          <div class="right-column">
            <div class="author">Автор: {pathname.includes('en') ? post.authorEn : post.author}</div>
            <div class="description">{pathname.includes('en') ? parse(post.descriptionEn) : parse(post.description)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkContent;
