import React, { useRef, useEffect } from 'react';

const AnimatedImage = ({ src, className, alt }) => {
  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate');
          } 
        });
      },
      { threshold: 0.1 } // Измените значение по вашему усмотрению
    );

    observer.observe(imageRef.current);

    return () => {
      observer.unobserve(imageRef.current);
    };
  }, []);

  return (
    <div className="animated-image-container">
      <img
        ref={imageRef}
        className={`animated-image ${className}`}
        src={src}
        alt={alt}
      />
    </div>
  );
};

export default AnimatedImage;
