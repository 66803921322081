const config = {
  en: {
    nav_about_title: 'About the festival',
    nav_about_main: 'Main',
    nav_gallery: 'Gallery of works',
    nav_archive: 'Archive',
    nav_lang: 'RU',
    title_about: 'About the festival',
    text_about: `
    It is an online exhibition devoted to the LGBTQ+ community. Authors from Russia get the
    chance to openly and safely speak and be heard.
    Artists, photographers, video makers, and musicians will capture the reality in their works.
    Through art, we will discuss the conditions of LGBTQ+ in the country and the pressure the
    authorities exert on the community; we will see the life stories, distresses, and expressions
    of emotions of the members of the LGBTQ+ community.`,
    text_about_span: 'This year, Queerfest is a sight where we talk about queer culture through creative work.',
    span_about: 'Queerfest takes place for the 15th time',
    title_mission: 'Mission',
    text_mission: `Today, when LGBTQ+ in Russia is persecuted for its very existence, we have decided that a
    safe platform is essential where every author can courageously voice the community
    problems and call out the repressive legislation.
    The theme of the festival - Courage to speak
    Authors of works try to find the answers to the important questions: how to confront
    discrimination, find the strength to fight, and create in the circumstances hostile to LGBTQ+
    people.
    Today, when humanity is opposed by mass insanity, discovering and creating something
    kind is especially important.
    `,
    title_participants: 'Participants of the festival',
    text_participants: `Participants of the online exhibition - queer authors from Russia. Brave, bright, and open
    people who are not afraid to be themselves.
    They daringly stand against repression and take an active part in the battle for equality and
    justice.
    <br/>
    <br/>
    They inspire others to see the uniqueness and beauty of the diversity of gender and sexual
    identities. They raise the question about the acceptance of oneself and others, and struggle
    against discrimination and violence.
    `,
    title_theme: 'The theme of the festival',
    courage: 'Courage',
    to_speak: 'to speak',
    text_theme: `Authors of works try to find the answers to the important questions: how to confront
    discrimination, find the strength to fight, and create in the circumstances hostile to LGBTQ+ people.
    Today, when humanity is opposed by mass insanity, discovering and creating something
    kind is especially important.`,
    gallery_button: 'GALLERY OF WORKS',
    footer_copy: `Copyright © 2009—2023
    The International Festival of
    Queer Culture in Saint Petersburg
    `,
    footer_team: `
    The team of the Queerfest 2023:
    `,
    footer_design: 'Design: RESHETNЁV',
    footer_span: 'queerfest@comingoutspb.ru',
    footer_dev: 'Developer: perlmutter'
  },
  ru: {
    nav_about_title: 'О ФЕСТИВАЛЕ',
    nav_about_main: 'Главная',
    nav_gallery: 'Галерея работ',
    nav_archive: 'Архив',
    nav_lang: 'ENG',
    title_about: 'О ФЕСТИВАЛЕ',
    text_about: `Это онлайн-выставка, посвященная искусству ЛГБТК+ сообщества. Авторы из России получили шанс открыто и безопасно говорить и быть услышанными. Художники, фотографы, видеомейкеры и музыканты запечатлеют реальность в своих работах.
    Через искусство обсудим положение ЛГБТК+ в стране, давление со стороны власти на сообщество. Увидим жизненные истории, переживания и выражение эмоций участников ЛГБТК+ сообщества.
    `,
    text_about_span: 'В этом году Квирфест — место, где мы через творчество говорим о квир-культуре.',
    span_about: 'Квирфест проходит в 15-й раз',
    title_mission: 'Миссия',
    text_mission: `Сейчас, когда ЛГБТК+ в России преследуют за само существование, мы решили — нужна безопасная площадка, где каждый автор сможет смело высказаться о проблемах сообщества, о репрессивном законодательстве.`,
    title_participants: 'Участники фестиваля',
    text_participants: `Участники онлайн-выставки — квир-авторы из России. Смелые, открытые и яркие люди, которые не боятся быть собой. Они смело выступают против репрессий и принимают активное участие в борьбе за равноправие и справедливость. 
    <br/>
    <br/>
    Вдохновляют других видеть исключительность и красоту разнообразия гендерных и сексуальных идентичностей. Ставят вопросы о принятии себя и других, борьбе с дискриминацией и насилием.    
    `,
    title_theme: 'Тема фестиваля',
    courage: 'Смелость',
    to_speak: 'говорить',
    text_theme: `Авторы работ попробовали найти ответы на важные вопросы:  как противостоять дискриминации? Как найти силы на борьбу и творчество в условиях, которые могут быть враждебными к ЛГБТК+ людям?
    Сегодня, когда человечности противостоит массовое безумие, особенно важно искать и создавать что-то доброе.
  `,
    gallery_button: 'СМОТРЕТЬ ГАЛЕРЕЮ',
    footer_copy: `Copyright © 2009—2023
    Международный фестиваль Квир-культуры в Санкт-Петербурге`,
    footer_team: `
    Команда Квирфеста 2023: 
    `,
    footer_dev: 'Разработчица: perlmutter ',
    footer_design: 'Дизайнер: RESHETNЁV',
    footer_span: 'queerfest@comingoutspb.ru'
  }
}

export default config;