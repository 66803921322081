import React from "react";
import img18 from "../images/18.png";
import fill10 from '../images/IG_Glyph_Fill-10.png';
import fill11 from '../images/IG_Glyph_Fill-11.png';
import fill14 from '../images/IG_Glyph_Fill-14.png';
import fill16 from '../images/IG_Glyph_Fill-16.png';
import fill1 from '../images/1.png';

const Footer = ({ localization }) => {
  localization = localization.localization;
  return  (
    <section class="footer-light wf-section">
    <div class="w-row">
      <div class="column-4 w-col w-col-11">
        <div class="socials">
          <div class="instagram">
            <a href="https://instagram.com/comingoutspb?igshid=MzRlODBiNWFlZA==" class="w-inline-block"><img src={fill10} loading="lazy" alt="" class="image-13"/></a>
          </div>
          <div class="vk">
            <a href="https://vk.com/comingoutspb" class="w-inline-block"><img src={fill11} loading="lazy" alt="" class="image-12"/></a>
          </div>
          <div class="twitter">
            <a href="https://twitter.com/comingoutspb" class="w-inline-block"><img src={fill14} loading="lazy" alt="" class="image-17"/></a>
          </div>
          <div class="fb">
            <a href="https://www.facebook.com/comingoutspb" class="w-inline-block"><img src={fill16} loading="lazy" alt="" class="image-16"/></a>
          </div>
          <div class="yt">
            <a href="https://www.youtube.com/user/ComingOutSPb" class="w-inline-block"><img src={fill1} loading="lazy" alt="" class="image-15"/></a>
          </div>
        </div>
      </div>
    </div>
    <div class="w-row">
      <div class="column-10 w-col w-col-5">
        <div class="text-block-7">{localization.footer_copy}</div>
      </div>
      <div class="column-9 w-col w-col-7">
        <div class="text-block-8">
          {localization.footer_team}
        <span>{localization.footer_span}</span>
        <div>{localization.footer_design}</div>
        <div>{localization.footer_dev}</div>
        </div>

      </div>
    </div>
  </section>
  );
}

export default Footer;
