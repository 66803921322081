import Header from '../components/header';
import Footer from '../components/footer';
import WorkContent from '../components/WorkContent';
import React from 'react';

const Work = (localization) => (
  <React.Fragment>
    <Header localization={localization}/>
    <WorkContent localization={localization} />
    <Footer localization={localization}/>
  </React.Fragment>
);

export default Work;