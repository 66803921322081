import React from 'react';
import Header from '../components/header';
import Content from '../components/content';
import Footer from '../components/footer';

const Main = (localization) => (
  <React.Fragment>
    <Header localization={localization} />
    <Content localization={localization} />
    <Footer localization={localization} />
  </React.Fragment>
);

export default Main;